.profile-badge-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5eaee;
  background-color: white;
  padding: 2rem 1.4rem;
  margin-top: 0.5rem;
}

.profile-badge-icon {
  border-radius: 50%;
  border: 1px solid #62b5e5;
  color: #62b5e5;
  margin-bottom: 0.5rem;
  padding: 0.5em;
}

.profile-badge-icon svg {
  padding: 0.5rem;
}

.profile-badge-data {
  color: #455a65;
}