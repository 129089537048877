@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap');

html {
    box-sizing: border-box;
  }

*, *:before, *:after {
  box-sizing: inherit;
}

* {
  font-family: 'Helvetica Neue', 'Open Sans', 'Roboto', sans-serif;
}


#root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
}