@import url(https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap);
.auth-btn {
  background-color: #62b5e5;
  color: white;
  height: 2.5rem;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  text-align: center;
  font-size: 1em;
  width: 100%;
  margin-top: 0.1em;
};
#trackit-container {
  width: 100vw;
  height: 80vh;
}
.profile-badge-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5eaee;
  background-color: white;
  padding: 2rem 1.4rem;
  margin-top: 0.5rem;
}

.profile-badge-icon {
  border-radius: 50%;
  border: 1px solid #62b5e5;
  color: #62b5e5;
  margin-bottom: 0.5rem;
  padding: 0.5em;
}

.profile-badge-icon svg {
  padding: 0.5rem;
}

.profile-badge-data {
  color: #455a65;
}
#new-vital-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5em;
}

#measure-and-unit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  margin-bottom: 0.25em;
}

#vital-card-title {
  font-size: 1rem;
  color: #67899C;
  align-self: center;
}

#vital-card-icon {
  color: #62b5e5;
  align-self: center;
  margin-bottom: 4em;
}

.measure {
  width: 50%;
  background-color: white;
  color: #67899C;
  height: 2rem;
  box-sizing: border-box;
}

#add-vital-btn {
  background-color: rgb(173, 220, 145) !important;
  color: white;
  height: 2.5rem;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  text-align: center;
  font-size: 1em;
}

#unit-select {
  border-left: none;
  color: inherit;
  font-family: inherit;
  font-size: 0.8em;
  margin-left: 0.25rem;
}
.vital-card-link {
  text-decoration: none;
  color: inherit;
}
#under-construction-wrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

html {
    box-sizing: border-box;
  }

*, *:before, *:after {
  box-sizing: inherit;
}

* {
  font-family: 'Helvetica Neue', 'Open Sans', 'Roboto', sans-serif;
}


#root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
}

